import { computed, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  user = signal<User | null>(null);
  isLoggedIn = signal(false);
  userInfo = computed(() => {
    const user = this.user();
    if (user) {
      return user;
    }
    const token = localStorage.getItem('access_token');
    const payload = token ? JSON.parse(atob(token.split('.')[1])) : null;
    return {
      email: payload?.email,
      role: payload?.role,
      plan: payload?.plan
    };
  });
}

export interface User {
  email: string;
  role: string;
}

export interface ValidateSubscription {
  isActive: boolean;
  plan: string
}


